
.headerContainer {
  background-color: var(--main-color);
  border-bottom: 5px solid var(--gad-teal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 11;
  padding: 0;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
}

.textContainer,
.mainHeader {
  position: relative;
  z-index: 11;
}

.custom-text-front {
  position: relative;
  z-index: 10; 
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 127, 163, 0.65);
  z-index: 2; 
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: auto;
  background-position: center;
  z-index: -1;
}



.mainHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 60%;
  min-width: 1128;
  max-width: 1400px;
}

@media (max-width: 1880px) {
  .mainHeader {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3px;
  }
  .mainHeader h1 {
    text-align: center;
  }
}

/* @media (max-width: 860px) {
  .mainHeader h1 {
    font-size: 2rem !important;
  }
} */

.mainHeader h1 {
  color: white;
  font-weight: 700;
  font-size: 2.35rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  background: rgb(0, 127, 163, 0.25);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

}

