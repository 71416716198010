.input_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 60%;
}

.input_container section {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input_container p {
  margin: 0;
}

.input_container select,
.input_container input {
  height: 1.75rem;
  width: 30%;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.input_container button {
  display: block;
  border: 2px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 600;
  height: fit-content;
}

.input_container button:hover {
  cursor: pointer;
  border: 2px solid rgb(0, 127, 163, 0.85);
  color: rgb(0, 127, 163, 1);
}

.ErrorMsg {
  /* background-color: rgb(255, 0, 0, 0.2); */
  padding-top: 0.5rem;
  color: rgb(255, 0, 0);
  /* border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 60%; */
}
