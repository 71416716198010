/* open-sans-regular - latin */
@font-face {
  font-family: "Arial Bold", Arial, sans-serif;
  font-style: bold;
  font-weight: 400;
  src: local(""),
    url("./assets/fonts/open-sans-v34-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v34-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* * {
  outline: 1px solid black;
} */

html {
  font-family: Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  /* line-height: 1.5; */
}

#root {
  --main-color : rgb(0, 127, 163);
  --main-color-light : rgb(0, 127, 163, 0.85);
  --gad-teal : rgb(51, 63, 72);
  --light-grey: rgb(210, 213, 215);
  --full-legacy : rgb(0, 175, 215, 0.6);
  --legacy-curtailed : rgb(0, 127, 163, 0.85);
  --CARE : rgb(51, 63, 72, 1);
}


body {
  padding: 0;
  border: 0;
  margin: 7.5rem auto 2.5rem auto;
  align-self: center;
  width: 60%;
  min-width: 900px;
  max-width: 1350px;
  --main-color : rgb(0, 127, 163);
  --main-color-light : rgb(0, 127, 163, 0.85);
  --gad-teal : rgb(51, 63, 72);
  --light-grey: rgb(210, 213, 215);
  --full-legacy : rgb(0, 175, 215, 0.6);
  --legacy-curtailed : rgba(0, 127, 163, 1);
  --CARE : rgbrgb(51, 63, 72, 0.85)
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

p,
button {
  font-size: 1rem;
}

h1,
h2,
h3,
p,
ul {
  margin: 0 0 0.75rem 0;
}

p:last-child,
p:only-child {
  margin: 0;
}

table,
th,
td {
  border-collapse: collapse;
  margin: 0 0 0.75rem 0;
  font-size: 1rem;
  border: 1px solid;
  padding: 0.5rem;
}

a {
  color: var(--main-color);
}

.custom-text {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: rgb(210, 213, 215);
  margin-bottom: 5px; 
}

.textContainer {
  border-left: 2px solid rgb(210, 213, 215); 
  padding-left: 10px; 
  height: 20%;
  z-index: 11;
}