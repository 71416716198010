.background {
  position: fixed;
  z-index: 15;
  background-color: hsla(0, 0%, 0%, 0.75);
  width: 800vw;
  height: 1000vh;
  top: 0;
  left: 0;
}

.modal {
  box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.2);
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  padding: 1.2rem;
  text-align: center;
  width: 30rem;
  position: fixed;
  z-index: 20;
  top: 20vh;
  left: calc(50% - 15rem);
}

.modal p {
  text-align: justify;
}

.btn_main {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  background-color: var(--main-color);
  color: hsl(0, 0%, 100%);
  border: 1px solid var(--main-color);
}

.btn_main:hover {
  background-color: var(--main-color-light);
  border-color: var(--main-color-light);
}

.modal img {
  margin-bottom: 30px
}
