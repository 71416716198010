.container {

    flex-grow: 1;
    width: 50%;
  }
  
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto repeat(1, 1fr); /* This ensures 4 rows: 1 for CARE, 1 for Legacy, and 2 for boxes */
    gap: 20px;
}

.title_container {
  display: flex;
  font-size: 1.5rem; /* or any suitable font size */
  font-weight: bold;
  align-self: center;
  grid-column: span 2;
  margin-bottom: -15px;
}

.title {
width: 50%;
}

.cell {
    background: linear-gradient(to left, var(--col), var(--lighter));
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    border-radius: 10px;
}

.cell.overwrite {
  margin-right:0;
  height: 150px;
}

.cell:hover {
    
    filter: brightness(110%);
    transform: scale(1.05);
    cursor: pointer;
}

.image {

    transition: opacity 0.3s ease-in-out;
}

.cell:hover .image {
    opacity: 1;
}

.text {
    text-align: center;
    margin-top: -10px;
}

.text > p:first-of-type {
  font-weight: 700
}

.help_button {
    position: absolute;
    top: 5%;
    right: 5%;
    display: block;
    border: 2px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: white;
    font-size: 0.8rem;
    font-weight: 600;
    height: fit-content;
  }
  
.help_button:hover {
  cursor: pointer;
  border: 2px solid rgb(0, 127, 163, 1);
  color: rgb(0, 127, 163, 1);
}

.grid img {
  height: 50px;
  transform: translateY(-20px)
}
