.switch_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switch_container :not(:last-child) {
  margin-right: 0.5rem;
}

.chart_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.chart {
  width: 50%;
  height: 26rem;
}

.joint_container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.joint_container :first-child {
  margin-right: 0.5rem;
}

.chart2 {
  width: 66%;
  height: 26rem;
}
